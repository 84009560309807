<template>
  <div>
    <PageHeader />

    <b-button-group v-if="!isFormCreate" class="mb-2">
      <b-button
          v-for="(menu, i) in buttons"
          :key="i"
          :variant="menu.key === currentMenu ? '' : 'light'"
          @click="currentMenu = menu.key"
      >
        <b-icon
            :icon="menu.icon"
            :variant="menu.key === currentMenu ? 'light' : ''"
        /> {{ menu.text }}
      </b-button>
    </b-button-group>

    <b-overlay
        :show="user.isAddingBankAccount || user.isUpdatingBankAccount"
        rounded
    >
    <b-card>
      <div v-if="user.isFetchingBankAccount" class="text-center">
        <b-spinner />
      </div>
        <BankAccountForm
            v-else
            :is-form-create="isFormCreate"
            :current-menu="currentMenu"
        />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  page: {
    title: 'แก้ไขบัญชีธนาคาร',
  },
  components: {
    BankAccountForm: () =>
      import('@components/forms/bank-account/form'),
  },
  data() {
    return {
      currentMenu: 'information',
      buttons: [
        {
          key: 'information',
          text: 'ข้อมูลบัญชี',
          icon: 'file-person-fill'
        },
        {
          key: 'autoBanking',
          text: 'ตั้งค่าระบบออโต้',
          icon: 'link45deg'
        },
        {
          key: 'autoTransfer',
          text: 'ตั้งค่าระบบโยกเงิน',
          icon: 'arrow-repeat'
        }
      ]
    }
  },
  computed: {
    ...mapState(['user']),
    isFormCreate() {
      return this.$route.name === 'CREATE_BANK_ACCOUNT'
    },
    bankAccountId() {
      return this.isFormCreate ? null : this.$route.params.id
    },
  },
  created() {
    if (this.bankAccountId) {
      this.fetchBankAccount(this.bankAccountId)
    } else {
      this.clearBankAccountForm()
    }
  },
  methods: {
    ...mapActions(['fetchBankAccount', 'clearBankAccountForm']),
  },
}
</script>
